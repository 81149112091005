import PropTypes from 'prop-types';

import { useI18n } from 'modules/i18n';
import { AuthShowContainer, AuthorizedContent, useAuthorization } from 'modules/auth';
import { SingleValueWithFormContainer } from 'modules/forms';
import InterviewDownloads from './InterviewDownloads';

export default function InterviewTextMaterials({
    interview,
    project,
    locale,
    isCatalog,
}) {

    const { t } = useI18n();
    const { isAuthorized } = useAuthorization();
    const showObservations = isAuthorized(interview, 'update') || interview.properties?.public_attributes?.observations?.toString() === 'true';

    if (!interview.language_id) {
        return null;
    }

    return (
        <>
            <AuthShowContainer ifLoggedIn>
                <p>
                    <span className='flyout-content-label'>{t('activerecord.attributes.interview.observations')}:</span>
                    { interview.translations && Object.values(interview.translations).map( ({ locale }) => {
                        return (
                            <InterviewDownloads
                                lang={locale}
                                type='observations'
                                condition={showObservations && interview.observations?.[locale]}
                                showEmpty={true}
                            />
                        )
                    })}
                </p>
            </AuthShowContainer>
            <AuthorizedContent object={interview} action="update">
                {
                    <SingleValueWithFormContainer
                        obj={interview}
                        collapse
                        elementType="textarea"
                        multiLocale
                        attribute={'observations'}
                        noLabel
                    />
                }
            </AuthorizedContent>
            {!isCatalog && (
                <AuthShowContainer ifLoggedIn>
                    <p>
                        <span className='flyout-content-label'>{t('transcript')}:</span>
                        { interview.languages.map( lang => {
                            return (
                                <InterviewDownloads
                                    lang={lang}
                                    type='transcript'
                                    condition={interview.segments?.[1]?.[interview.first_segments_ids[1]]}
                                    showEmpty={true}
                                />
                            )
                        })}
                    </p>
                </AuthShowContainer>
            )}
        </>
    );
}

InterviewTextMaterials.propTypes = {
    locale: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    interview: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
};
